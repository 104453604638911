<template>
    <v-card class="d-block d-sm-flex justify-center align-center transparent" height="100vh" width="100vw">   
        <v-row class="pa-5 pt-12 ma-auto text-center pa-sm-auto">
            <v-col cols="12" sm="10" md="7" lg="5" class="ma-auto pa-0 my-border"> 
                <div class="ma-0 pa-0 customlightgray">
                    <div class="ma-0 pa-0 py-6">
                        <v-img
                            class="d-block pa-0"
                            height="100"
                            contain
                            :src="require('../../assets/axcess1_logo_v2.png')"
                        ></v-img>
                    </div>
                    <v-form v-on:submit.prevent class="pa-2 pa-sm-12"
                            ref="adminform"
                            v-model="adminvalid"
                            lazy-validation >
                            <v-text-field
                                v-model="username"
                                type='text'
                                outlined
                                :label="$t('username')"
                                :rules="[rules.required, rules.minU]"
                                counter
                                prepend-icon="mdi-account"
                                autofocus
                                autocomplete="off"
                                @keydown.enter="loginAdmin"
                            ></v-text-field>
                            <v-text-field
                                v-model="password"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show ? 'text' : 'password'"
                                :label="$t('password')"
                                prepend-icon="mdi-lock"
                                counter
                                outlined
                                autocomplete="off"
                                @click:append="show = !show"
                                @keydown.enter="loginAdmin"
                            ></v-text-field>
                            <v-btn class="pa-auto ma-auto custom-color-accent font-weight-medium" @click="loginAdmin" depressed>{{$t("login")}}</v-btn>
                        </v-form>             
                </div>
                                               
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

export default({
    data() {
        return {
            adminvalid: true,
            code:'',
            username:'',
            password:'',
            show:false,
            rules: {
                required: value => !!value || this.$t('required'),
                min: v => v.length >= 8 || this.$t('mincharacters'),
                minU: v => v.length >= 3 || this.$t('mincharacters3'),
            },
        }
    },
    methods: {
        loginAdmin() {
           if(this.$refs.adminform.validate()){
                this.$store.dispatch('retriveAdminToken', { 
                    username: this.username,
                    password: this.password,
                    }).then(() => {
                        this.$store.dispatch('retriveUserInfo').then(()=>{
                          let item = {text:this.$t("welcome")+'!', type:"success", alert:true};
                          this.$emit('callAlert',item);
                          this.$router.push({ name: 'admin' });
                        })
                    }).catch(() => {
                            let item = {text:this.$t("loginfailedalert"), type:"dark", alert:true};
                            this.$emit('callAlert',item);
                        })
           }
        },     
    }
})

</script>
<style scoped>
</style>