<template>
  <div class="pa-0 ma-0">
      <div class="v-fade" ><loginform @callAlert="callAlert"></loginform></div>
      <alertVue
        :key="alertId"
        :alert.sync="alert"
        :alertText.sync="alertText"
        :alertType.sync="alertType"
      ></alertVue>
  </div>
</template>
<script>
import alertVue from "@/components/Alert";
import loginform from "@/components/login/loginAdminForm";

export default {
  components: {
    alertVue,
    loginform,
  },
  data() {
    return {
      alertText:'',
      alertType:'',
      alert:'',
      alertId:'',
    };
  },
  created() {
    this.$i18n.locale = localStorage.getItem("language");
  },
  methods: {
      callAlert(item) {
        this.alertText = item.text;
        this.alertType = item.type;
        this.alert = item.alert;
        this.alertId = Date.now();
      }
  }
}
</script>

<style lang="scss" scoped>

.v-fade {
  display: inherit !important; /* override v-show display: none */
  animation-timing-function: ease-in-out;
  transition: opacity 1.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>